<template>
  <div class="flex flex-row items-end w-full gap-2 p-3 pb-0 border-t border-gray-300">
    <!-- Label styled as a button -->
    <div class="flex items-end flex-1 overflow-hidden border border-gray-300 rounded-lg focus-within:border-gray-400">
      <!-- <button
        class="box-content flex items-center justify-center w-5 p-2 mb-0.5 text-gray-600 bg-white rounded-full cursor-pointer aspect-square hover:rotate-12 active:scale-90 focus-within:rotate-12 ring-gray-500 ring-offset-2"
        @click="open()"
      >
        <IconResolver icon-name="attachment" />
        <span class="sr-only">Upload file</span>
      </button> -->
    
      <textarea 
        id="file-upload" 
        ref="textareaInput"
        v-model="inputText" 
        placeholder="Message Sidekick..." 
        rows="1"
        class="flex-1 w-full p-2 py-2.5 overflow-y-auto text-sm outline-none resize-none "
        :style="{ maxHeight: maxTextAreaHeight + 'px' }"
        @keydown.meta.enter.stop="onSubmit"
      />
    </div>
    <div>
      <button
        v-if="isChatReady"
        class="w-10 p-3 text-white transition-transform duration-75 ease-in-out rounded-lg shadow outline-none cursor-pointer aspect-square bg-primary-700 hover:bg-primary-800 active:scale-95 focus-visible:ring-2 ring-primary-800 ring-offset-2"
        @click="onSubmit"
      >
        <IconResolver icon-name="paper-plane" />
        <span class="sr-only">Submit</span>
      </button>
      <button
        v-else
        class="w-10 p-3 text-white transition-transform duration-75 ease-in-out rounded-lg shadow outline-none cursor-pointer aspect-square bg-primary-700 hover:bg-primary-800 active:scale-95 focus-visible:ring-2 ring-primary-800 ring-offset-2"
        @click="abortFetch"
      >
        <div class="w-full p-1 scale-125 border border-white rounded-full">
          <div class="w-full bg-white aspect-square " />
        </div>
        <span class="sr-only">Stop generating...</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">


const emit = defineEmits<{
  (event: 'send-message', message: string): Promise<void>
  (event: 'abort'): Promise<void>

}>()
const props = defineProps<{
  isChatReady: boolean
}>()
const inputText = ref('');
const maxLinesVisible = 6;
const lineHeight = 20; // Assuming each line is 24px in height.
const maxTextAreaHeight = computed(() => lineHeight * maxLinesVisible);

// const {open, onChange, reset} = useFileDialog({
//   accept: props.acceptedFileTypes, // Set to accept only image files
//   multiple: true, // Allow selecting multiple files
// })
// onChange(async (files) => {
//   if(!files) return
//   const filesToBeUploaded = Array.from(files); // Assuming 'files' is a FileList or similar
//   emit('upload-files',filesToBeUploaded)
  
//   reset()
// })


const abortFetch = () => {
  emit('abort')
}

const textareaInput = ref<null | HTMLInputElement >(null)
watch(() => inputText.value,()  => adjustTextAreaHeight(inputText.value === ''))

const onSubmit = () => {
  if(!props.isChatReady) return


  if(inputText.value.trim() === '') return
  
  emit('send-message', inputText.value)
  inputText.value = ''
 
}

const adjustTextAreaHeight = (reset?: boolean):void => {
  if(!textareaInput.value) return
  const textarea = textareaInput.value;
  if(reset) {

    textarea.style.height = 'auto';
    return
  } else {
    
    textarea.style.height = 'auto'; // Temporarily shrink to fit new content
    
    const lines = textarea.scrollHeight / lineHeight;
    
    if (lines <= maxLinesVisible) {
      textarea.style.height = `${textarea.scrollHeight}px`;
    } else {
      textarea.style.height = `${maxTextAreaHeight.value}px`;
    }
  }
}
</script>
