function useAbortController() {
  const abortController = ref(new AbortController());

  function resetAbortController() {
    abortController.value = new AbortController();
  }

  function abortFetch() {
    abortController.value.abort();
    resetAbortController();
  }

  return { abortController, abortFetch };
}

export default useAbortController;