<template>
  <div class="fixed right-0 z-40 bottom-4 custom-position">
    <div class="absolute bottom-0 right-4">
      <SidekickToggleButton v-model="isToggled" />
    </div>

    <Transition
      enter-active-class="transition-all duration-100 origin-bottom-right ease-out-out"
      enter-from-class="transform scale-[0.25] opacity-50 origin-bottom-right"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="duration-100 ease-in-out origin-bottom-right scale-100"
      leave-from-class="scale-100 opacity-100"
      leave-to-class="transform scale-[0.25] opacity-50 origin-bottom-right"
    >
      <SidekickContainer
        v-show="isToggled"
        v-model="isToggled"
      >
        <!-- <FormDragAndDropContainer
          class="h-full"
          :allowed-file-types="acceptedFileTypes.split(',')"
          :disabled="uploadingFiles"
          @update="handleFileUpload"
        > -->
        <div
          class="flex flex-col items-start justify-start w-full h-full overflow-hidden"
        >
          <SidekickHeader
            @close="isToggled = false"
            @clear-chat="handleClearChat"
          />
          <!-- <pre>{{ data }}</pre>
          <button />{{ status }} -->
          <SidekickChatLog 
            :chat-log="chatLog"
            @send-message="sendMessage" 
          />

          <!-- :accepted-file-types="acceptedFileTypes" -->
          <!-- @upload-files="handleFileUpload" -->
          <SidekickForm
            :is-chat-ready="isChatReady"
            @send-message="sendMessage"
            @abort="handleAbort"
          />
          <p class="text-[10px] w-full text-center text-gray-600 py-1">
            The accuracy of responses may vary. Press ⌘ CMD + ⏎ Enter to send. 
          </p>
        </div>
        <!-- </FormDragAndDropContainer> -->
      </SidekickContainer>
    </Transition>
  </div>
</template>

<script setup lang="ts">
// import { getMimeTypes, partitionFilesBySizeLimit } from '@/utils/file';
import { useDeckmatchUserStore } from '~/stores/deckmatchUser';
import type { ChatMessage } from '~/types';


const isToggled = ref(false);


const isChatReady = computed(() => {
  if(!chatLog.value) return true
  if(chatLog.value.length === 0) return true
  const latestMessage = chatLog.value[chatLog.value.length - 1]
  return !(latestMessage.status === 'thinking' ||  latestMessage.status === 'answering')

})


const route = useRoute();
const parsedDeckId = ref(route.params.deckId as string);

const chat_id = ref<string | null>(null);

const userStore = useDeckmatchUserStore();


const chatLog = ref<ChatMessage[]>([])

const addMessageToChat = (message:Omit<ChatMessage, 'datetime'>) => {
  chatLog.value.push({...message, datetime: new Date().toISOString()})
}
const updateLatestMessage = (message: string) => {
  if(chatLog.value.length === 0) return 
  const latestMessageObject = chatLog.value[chatLog.value.length - 1]
  chatLog.value[chatLog.value.length - 1] = {
    ...latestMessageObject,
    message: latestMessageObject.message + message,
    status:'answering'
  }
}
const updateLatestMessageObject = (change:ChatMessage) => {

  if ( chatLog.value.length > 0) {
    chatLog.value[ chatLog.value.length - 1] = {
      ... chatLog.value[ chatLog.value.length - 1],
      ...change
    };
  }
}


const runtimeConfig = useRuntimeConfig();
const { deckmatch } = runtimeConfig.public;
const url = `${deckmatch.baseUrl}/${deckmatch.version}`;
// const url = 'https://ai-assistant.deckmatch.com/api/v1';


const { abortController, abortFetch } = useAbortController();

onUnmounted(() => {
  abortFetch(); // Cleanup to prevent memory leaks
});

const handleAbort = () => {
  abortFetch();
}

function sendMessage(message: string) {
  sendChatMessageAndHandleStream(message, abortController.value).then(() => {
    // console.log('Message sent successfully');
  }).catch(error => {
    // console.log(error.name);
    
    if (error.name === 'AbortError') {
      // console.log('Fetch aborted by the user');
      updateLatestMessageObject({status:'answered'})
    } else {

      updateLatestMessageObject({status:'failed'})
      console.error('Failed to send message:', error);
    }
  });
}

const sendChatMessageAndHandleStream = async (message:string, abortController: AbortController) => {
  const { first_name, last_name } = userStore.getUserInfo;
  addMessageToChat({ message, first_name, last_name })
  addMessageToChat({ message:'', first_name:'Sidekick', last_name: 'Chat', status: 'thinking'})
  
  const authorization = await useSilentAuth()
 
  const chatIdObject = chat_id.value && { chat_id: chat_id.value}
  const questionObject = { 
    question: message, 
    ...chatIdObject
  };

  const query = useDefaultQueries(questionObject)

  // const abortController = new AbortController(); 
  
  
    // const timeoutPromise = new Promise((_, reject) => 
    
    //   setTimeout(() => {
    //     abortController.abort();
        
    //     return _('')
    //     // return reject(new Error('Timeout - operation took too long'))
    //   }, 5000)
    // );
    const queryString = new URLSearchParams(query)
    const response = await fetch(`${url}/deck-chat/${parsedDeckId.value}/chat-answer/?${queryString}`, {
      method: 'POST',
      headers: {
        authorization
      },
      signal: abortController.signal
    });
    

    // const response = await Promise.race([fetchPromise, timeoutPromise]);

    if (!response.ok) {
      throw new Error('Failed to fetch');
    }
    // let message = '';
    const reader = response.body?.getReader();
    const decoder = new TextDecoder();
    let read;
    chat_id.value = response.headers.get('chat-id') || null
    
    while (!(read = await reader?.read()).done) {
      const chunk = read.value;
      updateLatestMessage(decoder.decode(chunk));
    }
    updateLatestMessageObject({status:'answered'})  
  
}

const handleClearChat = () => {
  chatLog.value = []
}









// files
// const maxFileSize = ref(104_857_600); // 100MB · Arbitrary max file size of 100

// const acceptedFileTypes = ref(getMimeTypes(['pdf', 'ppt', 'pptx', 'key']));
// const listToBeUploaded = ref<string[]>([]);
// const uploadingFiles = ref(false);

// const handleFileUpload = async (files: File[] | null) => {
//   if (!files) return;

//   const [filesThatAreWithinLimit, filesThatAreTooLarge] =
//     partitionFilesBySizeLimit(files, maxFileSize.value);
//   if (filesThatAreTooLarge.length > 0) {
//     toast.addToast({
//       type: 'warning',
//       dismissible: true,
//       iconName: 'exclamation',
//       title: 'File too large',
//       text: `The following files are too large: ${filesThatAreTooLarge.map(file => file.name).join(', ')}. Please upload files that are less than 100MB.`,
//       slim:true,
//       dismissAfter: 5000,
//     });
//   }
//   uploadingFiles.value = true;

//   listToBeUploaded.value = filesThatAreWithinLimit.map((file) => file.name);
//   // Upload the files
//   for (const file of filesThatAreWithinLimit) {
//     await currentDeckStore
//       .uploadFile(parsedDeckId.value, file)
//       .catch((error) => {
//         toast.addToast({
//           type: 'error',
//           dismissible: true,
//           iconName: 'exclamation',
//           title: 'Something went wrong',
//           text: `Could not upload ${file.name}, please try again later.`,
//           slim:true,
//         });
//       })
//       .finally(() => {
//         listToBeUploaded.value = listToBeUploaded.value.filter(
//           (storedFileName) => storedFileName !== file.name
//         );
//       });
//   }
//   uploadingFiles.value = false;
// };


</script>
<style scoped>
.custom-position {
  right: 0;

  @media screen and (min-width: 2010px) {
    right: calc((100vw - 1560px) - 450px);
  }
}
</style>
