<template>
  <div class="flex justify-between text-xs text-gray-600">
    <p>{{ name }}</p>
    <p>{{ time && formatTime(time) }}</p>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  name?: string,
  time?: string
}>()



const dayjs = useDayjs()

const formatTime = (time: string) => {
  const today = dayjs();
  const last7Days = today.subtract(7, 'day');

  const givenDate = dayjs(time).utc()

  if(givenDate.isSame(dayjs(), 'day')) return givenDate.fromNow() // same day
  if (givenDate.isAfter(last7Days, 'day')) return givenDate.fromNow() // last 7 days
  return givenDate.format('ddd, MMM D, YYYY') // last 7 days
}
</script>
