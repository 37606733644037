<template>
  <div class="flex w-full gap-2 ">
    <SidekickMessageAvatar
      :first-name="content.first_name"
      :last-name="content.last_name"
    />
    <div class="w-[calc(100%_-_48px)] flex flex-col gap-1">
      <SidekickMessageHeader
        :name="name"
        :time="content.datetime"
      />
      <SidekickMessageContent
        :status="content.status"
        :text="content.message"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ChatMessage } from '@/types'
import { useDeckmatchUserStore } from '~/stores/deckmatchUser';
type Props = {
  content : ChatMessage
}
const props = defineProps<Props>()

const userStore = useDeckmatchUserStore()

const name = computed(() => {
  const { first_name, last_name } = userStore.getUserInfo
  if (props.content.first_name === first_name && props.content.last_name === last_name) {
    return 'You'
  }
  return `${props.content.first_name} ${props.content.last_name}`
})
</script>
