<template>
  <div
    class="w-6 sm:w-8"
    aria-hidden
  >
    <Avatar
      v-if="!isSidekick"
      :first-name="firstName"
      :last-name="lastName"
    />
    <div
      v-else
      class="relative p-1.5 bg-gray-200 rounded-full text-gray-950"
    >
      <IconResolver
        v-if="isSidekick"
        icon-name="deckmatch"
      />
      <div class="absolute w-3.5 border-2 border-white rounded-full -bottom-1 right-0.5 aspect-square bg-success-500 scale-75" />
    </div>
  </div>
</template>

<script setup lang="ts">
type Props = {
  firstName?: string,
  lastName?: string
}
const props = defineProps<Props>()
const isSidekick = computed(() => {
  return props?.firstName === 'Sidekick'
})
</script>

<style scoped>

</style>