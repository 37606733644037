
<template>
  <button
    class="relative p-2 text-white transition-transform duration-75 ease-in-out bg-gray-800 rounded-full shadow-xl w-14 active:scale-90"
    @click="model = !model"
  >
    <span class="sr-only">{{ model ? 'Open' : 'Close' }} Sidekick</span>
    <IconResolver
      aria-hidden
      :icon-name="model ? 'chat-chevron-down' : 'deckmatch'"
    />
    <div class="absolute w-3.5 border-2 border-white rounded-full -bottom-0.5 right-1 aspect-square bg-success-500" />
  </button>
</template>

<script setup lang="ts">
type Props = {

}
const model = defineModel()
defineProps<Props>()
</script>