<template>
  <div
    class=" draggable-container absolute right-0 md:right-4 -bottom-4 md:bottom-[70px]  bg-white h-[100vh] md:h-[600px] md:max-h-[calc(100vh_-_70px_-_36px)] shadow-2xl shadow-gray-900/30 md:rounded-2xl overflow-hidden"
    :style="{ width: `${containerWidth}px` }"
  >
    <div
      class="drag-handle"
      @mousedown.prevent="startDragging"
    />

    <slot />
  </div>
</template>

<script setup lang="ts">

const containerWidth = ref(Math.min(window.innerWidth * 0.9, 450)); // Initial width with max check
const isDragging = ref(false);
const initialX = ref(0);

const startDragging = (event: MouseEvent) => {
  initialX.value = event.clientX; // Capture the initial x position at drag start
  isDragging.value = true;
  document.addEventListener('mousemove', handleDragging);
  document.addEventListener('mouseup', stopDragging);
};

const handleDragging = (event: MouseEvent) => {
  if (!isDragging.value) return;

  // Calculate the difference between the current x position and the initial x position
  const deltaX = event.clientX - initialX.value;
  
  // In this case, subtracting deltaX increases the width when moving the mouse to the left,
  // and adding deltaX decreases the width when moving to the right.
  const newWidth = Math.max(300, Math.min(window.innerWidth * 0.9, containerWidth.value - deltaX));

  containerWidth.value = newWidth;

  // To ensure the container width adjusts in a linear manner with the mouse movement,
  // update initialX to the current event.clientX AFTER applying the width change.
  // This effectively 'resets' the drag start point to the new cursor location,
  // preventing exponential width changes and making the drag follow the cursor accurately.
  initialX.value = event.clientX;
};

const stopDragging = () => {
  isDragging.value = false;
  document.removeEventListener('mousemove', handleDragging);
  document.removeEventListener('mouseup', stopDragging);
};
// watchEffect(() => {
//   console.log(containerWidth.value);
// });

// Update containerWidth on viewport resize (optional)
const adjustWidth = () => {
  containerWidth.value = Math.min(window.innerWidth * 0.9, containerWidth.value )
};
onMounted(() => {

  // Adjust width on window resize
  window.addEventListener('resize', adjustWidth);
  
  // Initial adjustment if needed
  adjustWidth();
});

onUnmounted(() => {
  window.removeEventListener('resize', adjustWidth);
});
</script>

<style scoped>
@media screen and (max-width: 768px){
  .draggable-container {
    /* Existing styles... */
    width: 100vw !important; /* Full width on mobile */
  
  }
}

.drag-handle {
  /* Styles for the drag handle element */
  position: absolute;
  left: -10px; /* Adjust for handle size */
  top: 0; /* Center vertically */
  width: 20px; /* Adjust width of handle */
  height: 100%; /* Adjust height of handle */
  background-color: rgba(0, 0, 0, 0); /* Semi-transparent handle */
  cursor: ew-resize; /* Change cursor on hover */
}
</style>
