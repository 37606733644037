<template>
  <div
    class="grid flex-1 col-start-2 col-span-1 row-span-1 transition-all duration-150 grid-cols-[0fr]"
    :class="!isNavExpanded ? 'opacity-1' : 'ml-4 grid-cols-[1fr]'"
  >
    <div class="flex flex-col overflow-hidden">
      <div
        class=" grid grid-cols-[1fr_2rem]"
      >
        <span class="col-start-1 row-start-1 overflow-hidden text-sm font-semibold capitalize truncate">{{ user?.first_name }} {{ user?.last_name }}</span>
        <button
          class="block col-start-2 row-start-1 text-gray-400"
          @click="handleLogout"
        >
          <div class="w-5">
            <IconResolver icon-name="logout" />
          </div>
        </button>
      </div>
      <div 
        class="overflow-hidden text-sm text-gray-100 truncate"
      >
        {{ user?.email }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDeckmatchUserStore } from '@/stores/deckmatchUser';


interface Props {
  isNavExpanded: boolean
}

defineProps<Props>()

const deckmatchUserStore = useDeckmatchUserStore()
const user = computed(() => deckmatchUserStore.getUserInfo)

const returnTo = useCookie('returnTo')

const handleLogout = async () => { 

  returnTo.value = '/'
  const { $auth0 } = useNuxtApp()
  await $auth0.logout({
    logoutParams: {
      returnTo: window.location.origin === 'http://localhost:3000' ? 'http://localhost:3000' : 'https://deckmatch.com',
    },
  });
}

</script>