<template>
  <div
    v-if="isInDeckmatchDB"
    class="relative flex min-h-screen'"
  >
    <DashboardHeader :is-nav-expanded="isNavExpanded">
      <template #expansion-button>
        <button
          class="relative w-8 rounded-full aspect-square bg-primary-600"
          @click="isNavExpanded = !isNavExpanded"
        >
          <span
            class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
            :class="isNavExpanded ? 'rotate-180' : ''"
          >
            <IconResolver icon-name="chevron_right" />
          </span>
          <span class="absolute opacity-0">
            {{ isNavExpanded ? 'Close' : 'Open' }}
          </span>
        </button>
      </template>
    </DashboardHeader>
    <DashboardHeaderMobile :is-nav-expanded="isNavExpanded" />
    <div class="z-10 flex-1 pt-20 md:pt-4">

      <main :class="route.path === '/' && userSettingCookie.selectedView === 'board' ? '': 'pb-20'">
        <slot />
      </main>
    </div>
    <Sidekick v-if="!shouldSidekickBeHidden" />
  </div>
  <PageLoader v-else />
</template>

<script setup lang="ts">
import { useDeckmatchUserStore } from '~/stores/deckmatchUser';
const isNavExpanded = ref(true)
const route = useRoute()
const deckmatchUserStore = useDeckmatchUserStore()

const isInDeckmatchDB = computed(() => !!deckmatchUserStore.getUserInfo.email)


const shouldSidekickBeHidden = computed(() => {
  return !route.params.deckId
})

const userSettingCookie = useUserSettingCookie


</script>

<style>

.layout {
  display: flex; 
  height: 100dvh;

}


</style>