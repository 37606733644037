<template>
  <div
    ref="chatLogElement"
    class="flex flex-col items-stretch flex-1 w-full gap-6 px-3 py-4 pb-3 overflow-y-auto scroll-smooth"
  >
    <div
      v-for="({question, answer}, index) in groupQuestionAndAnswer"
      :key="question.datetime"
      :class="index === groupQuestionAndAnswer.length - 1 ? 'min-h-full' : ''"
      class="flex flex-col w-full gap-6"
    >
      <SidekickMessageContainer
        :content="question"
      />
      <SidekickMessageContainer
        v-if="answer"
        :content="answer"
      />
    </div>
    <div
      v-if="!groupQuestionAndAnswer.length"
      class="flex flex-wrap items-center w-full h-full overflow-hidden"
    >
      <div class="flex flex-col items-center w-full gap-3">
        <div class="p-2 bg-gray-100 rounded-full">
          <IconResolver
            icon-name="deckmatch"
            class="w-8 h-8 text-gray-950"
          />
        </div>
        <h2 class="text-sm font-semibold text-gray-950">
          What can I help you with?
        </h2>
      </div>
      <div class="flex self-end w-full gap-2 pb-3 -mb-3 overflow-x-auto justify-self-end">
        <SidekickPromptSuggestion
          v-for="suggestion in promptSuggestions"
          :key="suggestion.title"
          class="justify-end"
          :question="suggestion.question"
          :title="suggestion.title"
          :byline="suggestion.byline"
          @click="$emit('send-message', suggestion.question)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ChatMessage } from '~/types';

const chatLogElement = ref<HTMLElement | null>(null)

defineEmits<{
  (event: 'send-message', question: string): void
}>()
const props = defineProps<{
  chatLog: ChatMessage[]
}>()

const promptSuggestions = [
  {
    title: 'Polite rejection email',
    byline: 'Please produce a rejection email for the named CEO...?',
    question: 'Please produce a rejection email for the named CEO of this particular company, or failing that other senior founder, explaining that it doesn’t fit our sweetspot. Focus on the verdict data when looking for the reason, which is a shame as it looks like an interesting opportunity. If it is categorical acceptence = true, suggest that you don’t fully have conviction in the space. Keep it to 2 short paragraphs. Be polite, informal, complementary, and firm, so that there is no ambiguity.'
  },
  {
    title: 'Round Dynamics',
    byline: 'A walkthrough of the Cap Table',
    question: 'Walk me through the cap table - summarize the ownership of the company, valuations, and which investors own what - detail them by name, and bonus points for highlighting vesting schedule.'
  },
  {
    title: 'Competitive Arena',
    byline: 'What makes this unique and special?',
    question: 'Summarize the top 3 competitors and what makes this opportunity stand out. Use bullets, remove superlative adjectives, and be concise.'
  },
  {
    title: 'Defensibility',
    byline: 'How easy is it to replicate?',
    question: 'Could you, drawing from a mix of this context and your own knowledge, explain how easy it would be to copy this. In other words weigh the strengths and weaknesses of the defensibility here. Keep your response concise, void of superlatives, and user bullets.'
  },
  {
    title: 'Size of Problem',
    byline: 'Is this a venture scale problem?',
    question: 'Why is this a problem worth solving? Does it have the potential to be a large market if solved adequately? Your default starting point is to be genuinely sceptical of the validity of the problem. Keep your answers to bullet with the text being concise, and void of superlatives.'
  },
]



const groupQuestionAndAnswer = computed(() => {
  // every odd message is a question and every even message is an answer
  // the first message is always a question and is connected to the following message
  const messages = props.chatLog
  const groupedMessages = []
  for (let i = 0; i < messages.length; i += 2) {
    const question = messages[i]
    const answer = messages[i + 1]
    if (answer) {
      groupedMessages.push({ question, answer })
    } else {
      groupedMessages.push({ question })
    }
  }
  return groupedMessages
})


const scrollToBottom = () => {
  if(chatLogElement.value) {
    nextTick(() => {
      chatLogElement.value.scrollTop = chatLogElement.value.scrollHeight
    })
  }
}

watch(() => props.chatLog, (newValue, oldValue) => {
  if(newValue?.[newValue.length - 1]?.status === 'thinking' ) {
    scrollToBottom()
  }
}, { deep: true})

</script>
